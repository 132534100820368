<template>
  <div
      class="cws-container cws-sidebar-right blog-wrapper"
  >

    <!-- content -->
    <div class="blog-detail-wrapper" v-if="trackDetail">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card
              _img-top
              _img-alt="Постер"
              _:title="`${trackDetail.track.title}`"
          >
            <h1 class="d-flex">{{ trackDetail.track.title }}</h1>

            <b-row v-if="trackDetail.track.file">
              <b-col cols="12">
                <b-button
                    variant="success"
                    :href="trackDetail.track.url"
                    icon="SaveIcon"
                >
                  <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Загрузить</span>
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                  cols="12"
                  md="12"
                  class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
              >
                <b-card
                    title="Поиск песни"
                    class="col-md-12 col-12"
                >
                  <b-overlay
                      :show="isLoadingTrack"
                      rounded="sm"
                  >
                    <b-row>
                      <b-col md="12">

                        <div class="my-1 py-25">
                          <b-table
                              ref="refTracksListTable"
                              :items="trackDetail.results"
                              responsive
                              :fields="tableColumns"
                              :tbody-tr-class="rowClass"
                              primary-key="id"
                              show-empty
                              empty-text="No tracks"
                              class="position-relative"
                          >

                            <!-- Column: Item -->
                            <template #cell(artist)="data">
                              <b-media vertical-align="center" v-if="data.item.main_artists">
                                <template #aside v-if="data.item.album && data.item.album.thumb">
                                  <b-avatar
                                      size="64"
                                      :src="data.item.album.thumb.photo_270"
                                      :text="avatarText(data.item.main_artists[0].name)"
                                      variant="primary"
                                  />
                                </template>
                                <span class="font-weight-bold d-block text text-success" v-for="artist in data.item.main_artists">
                                  {{ artist.name }},
                                </span>
                              </b-media>
                              <span class="text" v-else>
                                {{ data.item.artist }}
                                <span v-if="((data.item.artist != trackDetail.track.artist) || (data.item.title != trackDetail.track.track))">
                                  <b-button
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      variant="warning"
                                      class="btn-icon"
                                      size="sm"
                                      @click="updateTrackTitle(trackDetail.track.id, `${data.item.artist} - ${data.item.title}`)"
                                  >
                                    <feather-icon icon="ArrowUpCircleIcon"/>
                                  </b-button>
                                </span>
                              </span>
                            </template>

                            <!-- Column: Client -->
                            <template #cell(duration)="data">
                              <span class="text-nowrap">
                                {{ formatDuration(data.item.duration) }}
                              </span>
                            </template>

                            <!-- Column: Client -->
                            <template #cell(title)="data">
                              {{ data.item.title }}
                              <span class="small" v-if="data.item.subtitle">
                                {{ data.item.subtitle }}
                              </span>
                            </template>

                            <!-- Column: Client -->
                            <template #cell(date)="data">
                              <span class="text-nowrap">
                                {{ fromTimestamp(data.item.date) }}
                              </span>
                            </template>

                            <!-- Column: Client -->
                            <template #cell(actions)="data">
                              <span class="text-nowrap">
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="success"
                                    class="btn-icon"
                                    @click="downloadTrack(`${data.item.owner_id}_${data.item.id}`)"
                                >
                                  <feather-icon icon="DownloadIcon"/>
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    class="btn-icon"
                                    target="_blank"
                                    :href="`https://vk.com/audio${data.item.owner_id}_${data.item.id}`"
                                >
                                  <feather-icon icon="SearchIcon"/>
                                </b-button>
                              </span>
                            </template>
                          </b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-overlay>
                </b-card>
              </b-col>
            </b-row>

          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

  </div>
</template>

<script>
import {
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
  BTab,
  BTabs,
  BSpinner,
  BOverlay,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {avatarText, formatNumber, formatDate, formatDuration, fromTimestamp} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import router from "@/router";
import store from "@/store";
import musicStoreModule from "@/views/db-music/musicStoreModule";
import {onUnmounted, ref} from "@vue/composition-api";
import TrackRow from "@/views/db-music/TrackRow";

export default {
  components: {
    TrackRow,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    BSpinner,
    BOverlay,
    BTable,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {}
  },
  methods: {
    rowClass(item, type) {
      const colorClass = 'table-danger'
      if (!item || type !== 'row') {
        return
      }

      // eslint-disable-next-line consistent-return
      if (false) {
        return colorClass
      }
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'artists'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, musicStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const trackDetail = ref(null)
    const similar = ref([])
    const isLoadingTrack = ref(false)
    const isLoadingSuggestions = ref(false)
    const disabledTrackId = ref(null)

    // Table Handlers
    const tableColumns = [
      //{key: 'id', label: '#', sortable: false},
      {key: 'artist', sortable: true},
      {key: 'title', sortable: true},
      {key: 'duration', sortable: true},
      {key: 'is_licensed', sortable: true},
      {key: 'date', sortable: true},
      {key: 'actions'},
    ]

    const fetchTrackData = () => {
      let id = router.currentRoute.params.id
      store.dispatch('artists/searchTrack', {id: id}).then(res => {
        trackDetail.value = res.data
      })
    }

    const downloadTrack = (vkId) => {
      blockData()
      let id = router.currentRoute.params.id
      store.dispatch('artists/downloadTrack', {id: id, queryParams: {vkId: vkId}})
          .then(response => {
            refreshData()
          })
    }

    const updateTrackTitle = (id, title) => {
      blockData()
      store.dispatch('artists/updateTrack', {id: id, queryParams: {title: title}})
          .then(response => {
            refreshData()
          })
    }

    const refreshData = () => {
      blockData()
      fetchTrackData()
      unblockData()
    }

    const blockData = () => {
      isLoadingTrack.value = true
    }

    const unblockData = () => {
      isLoadingTrack.value = false
    }

    fetchTrackData()

    return {
      formatDate,
      trackDetail,
      similar,
      isLoadingTrack,
      disabledTrackId,
      refreshData,
      downloadTrack,
      updateTrackTitle,
      blockData,
      unblockData,
      tableColumns,
      avatarText,
      formatNumber,
      formatDuration,
      fromTimestamp,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
